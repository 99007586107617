.favorites-section {
    width: calc(100% - 67px - 312px ) !important;
}
.favorites-view
{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.favorites-view-container {
    position: relative;
    width: calc(100% - 100px);
    height: 70%;
    background: #FFFFFF;
    border: 1px dashed var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.favorites-view-container.has-item {
    border: 0;
    background: transparent;
}
.favorites-view-container .empty-text {
    text-align: center;
}
.favorites-view-container .empty-text h3 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 10px;
}
.favorites-view-container .empty-text p {
    color: #989898;
    font-size: 1.1em;
}

.compare-icon {
    display: inline-block;
    margin-bottom: 5px;
    width: 2em;
    height: 2em;
    background-color: var(--primary-color);
    mask-repeat: no-repeat;
    mask-size: 95%;
    mask-position: center;
    mask-image: url('../img/icon-compare.svg');
}

.compare-list {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5em;
}