.submenu-wrap {
    position: absolute;
    top: 0;
    right: 67px;
    width: 270px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    border-left: 1px solid var(--primary-border-color);
    background: white;

}

.submenu-wrap .inner {
    display: flex;
    align-items: center;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
.submenu
{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-height: 70vh;
    overflow: auto;
}
.submenu-item
{
    display: block;
    width: 100%;
    padding: 1.2em 2em;
    border-top : 1px solid var(--primary-border-color);
    color: #212529;
    font-size: 1em;
    line-height: 1em;

}


.submenu-item:hover,.submenu-item.submenu-item--active {
    background: var(--primary-color);
    color: white;
}

.submenu-item.last
{
    border-bottom: 1px solid var(--primary-border-color);
}

.submenu-title
{
    display: block;
    width: 100%;
    padding: 1.2em 2em;
    text-transform: uppercase;
    background: white;
    font-weight: bold;
    font-size: 1em;
    line-height: 1em;
    border-top : 1px solid var(--primary-border-color);

}
.search-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.submenu-item .property-status-icon {
    position: relative;
    display: inline-block;
    margin-left: 1rem;

    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;

}


.submenu-item .property-status-icon.available {
    background: var(--available-color);
}

.submenu-item .property-status-icon.sold {
    background: var(--sold-color);
}
.submenu-item .property-status-icon.reserved {
    background: var(--option-color);
}
.submenu-item .property-status-icon.not_released {
    background: var(--not-released-color);
}
