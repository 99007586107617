.fav-item {
    border: 1px solid var(--primary-border-color);
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;
}
.fav-item-img {
    width: 70px;
    height: 70px;
    margin: 1em;
    border-radius: 7px;
}

.fav-item-text {
    padding: 1.2em 0;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.fav-item-text h3 {
    font-size: 1.4em;
    margin-bottom: 0;
}
.fav-item-text span {
    display: block;
    font-size: 1.3em;
}
.fav-item-text .room-type-text {
    color: #8D8D8D;
}
.fav-item-buttons{
    width: 4em;
    display: flex;
    flex-direction: column;

}

.fav-item-buttons button{
    flex-grow: 1;
    position: relative;
    border-left: 1px solid var(--primary-border-color);
    border-bottom: 1px solid var(--primary-border-color);

    width: 100%;


}
.fav-item-buttons button.btn-compare-fav {
    border-bottom: 0;
}
.fav-item-buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.fav-item-buttons button .icon{
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    background-color: #C6C6C6;


}
.fav-item-buttons button.btn-delete-fav .icon{
    mask-image: url('../img/icon-delete-fav.svg');
    mask-repeat: no-repeat;
    mask-size: 95%;
    mask-position: center;
}

.fav-item-buttons button.btn-compare-fav .icon{
    mask-image: url('../img/icon-compare.svg');
    mask-repeat: no-repeat;
    mask-size: 95%;
    mask-position: center;
}
.fav-item-buttons button:hover,
.fav-item.in-compare-list .fav-item-buttons button.btn-compare-fav{
    background-color: var(--primary-color);
}

.fav-item-buttons button:hover .icon,
.fav-item.in-compare-list .fav-item-buttons button.btn-compare-fav .icon{
    background: white;
}
