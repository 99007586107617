.aws-btn {

    --slider-height-percentage: 60%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: var(--primary-color);
    --control-bullet-active-color: #26456f;
    --control-button-opacity : .8;
    --loader-bar-color: var(--primary-color);
    --loader-bar-height: 3px;
}


.active-slide
{
    position: absolute;
    right: 3rem;
    top: 3rem;
    font-weight: bold;
    font-size:1.2rem;
    color:#fff;
    z-index: 100;
}

.caption
{
    position: absolute;
    left: 3rem;
    top: 3rem;
    font-weight: bold;
    font-size:2rem;
    color:#fff;
    z-index: 100;
}
.caption.dark {
    color:#000
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}


.swiper-button-prev, .swiper-button-next {
    color :white
}
.swiper-button-prev {
    left: 30px
}
.swiper-button-next {
    right: 30px;
}
.swiper-slide {
    background-size: cover;
    background-position: center;
}

.main-swiper {
    height: 100vh;
    width: 100%;
}

.thumb-swiper {
    position: fixed;

    bottom: 0;
    height: 110px;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 20px;
}

.thumb-swiper  .swiper-slide {
    border-radius: 10px;
    opacity: 0.8;
    filter: brightness(80%);
}

.thumb-swiper .swiper-slide-thumb-active {
    filter: brightness(120%);
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.thumb-swiper  .swiper-slide img {
    border-radius: 10px;
}
.thumb-swiper .swiper-wrapper {
    justify-content: center;
}