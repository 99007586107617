.fav-list
{
    padding: 2em;
    height: calc(100vh - 69px);
    overflow-y: auto;

}

/* width */
.fav-list::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.fav-list::-webkit-scrollbar-track {
    background: #f7f7f7;
    margin-right: 10px;

}

/* Handle */
.fav-list::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 10px;
}


.button-delete {

    padding: 1em 2.8em;
    font-weight: normal;
    background: transparent;
    color: red;
    border: 1px solid red;
}
.no-fav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 69px);
}

.share-button
{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.7em 2em;
    font-weight: normal;
    border: 1px solid var(--primary-border-color);
    color: #333;
    margin-left: 0px;
    width: 100%;
}

.icon-share
{
    position: relative;
    width:1.5rem;
    height: 1.5rem;

}

.icon-share:before
{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 1.5em;
    height: 1.5em;
    background-color: var(--primary-color);;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url('../img/icon-share.svg');
}
.share-button:hover:not(.btn-disabled)
{
    color: white;
    background: var(--primary-color);
}
.share-button:hover:not(.btn-disabled) .icon-share:before
{
    background: url('../img/icon-share-light.svg') no-repeat center;
}