.btn-fullscreen {
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1;
}
.btn-fullscreen .icon{
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    background-color: white;


}
.btn-fullscreen .icon{
    mask-image: url('../img/icon-fullscreen.svg');
    mask-repeat: no-repeat;
    mask-size: 95%;
    mask-position: center;
}

.btn-fullscreen:hover .icon
{
    background: var(--primary-color);
}
