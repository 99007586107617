.filter-tag {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid var(--primary-border-color);
    padding: 4px 8px;

}

.filter-tag .delete-button {
    position: relative;
    width: 1em;
    height: 1em;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    padding: 7px;
    margin-left: 0.3em;
}

.filter-tag .delete-button > div {
    position: absolute;
    left: 2px;
    top: 6px;
    width: 0.8em;
    height: 0.14em;
    background: var(--primary-color);
    transform: rotate(45deg);
    transform-origin: center;
    border-radius: 10px;
}

.filter-tag  .delete-button > div:last-child {
    transform: rotate(-45deg);
}

.filter-tag .delete-button:hover {
    background: var(--primary-color);
}
.filter-tag .delete-button:hover > div {

    background: white;

}