.compare-list-item {
    position: relative;
    width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px -2px rgba(0, 0, 0, 0.25);
    margin: 0 1.5em;
}

.compare-list-item-text > div {
    display: flex;
    justify-content: space-between;
    padding: 1.1em 2em;
    border-bottom: 1px solid var(--primary-border-color);
}

.compare-list-item-text > div span:first-child {

}
.compare-list-item .close-button {
    top: 0;
    right: 0;
    width: 3em;
    height: 3em;
    background: var(--primary-color);
}
.compare-list-item .close-button > div {
    background: white;
    width: 50%;
    left: 8px;
    top: 17px;
}

.btn-review-details {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    padding: 2em;
}
.btn-review-details .icon{
    display: inline-block;
    width: 2em;
    height: 2em;
    margin-right: 0.7em;
    background: var(--primary-color);

}
.btn-review-details .icon{
    mask-image: url('../img/icon-go.svg');
    mask-repeat: no-repeat;
    mask-size: 95%;
    mask-position: center;
}

.btn-review-details:hover .icon
{
    background: var(--primary-color);
}