.main-menu .client-menu {
    position: fixed;
    right: 130px;
    bottom: 65px;
    background: white;
    box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.25);
}
.main-menu.narrow .client-menu {
    right: 94px;
}
.client-menu-link-container {
    display: flex;
}
.client-menu-link-container .client-menu-link {
    border-top: 1px solid var(--primary-border-color);
    flex-grow: 1;
    padding: 0 7px;
}
.client-menu-link-container .client-menu-link:first-child {
    border-right: 1px solid var(--primary-border-color);
}
.client-menu-link-container .client-menu-link button {
    width: 100%;
    border-radius: 0;
    padding: 1.5em 1em ;
    background: white;
    color: #333;
    font-weight: normal;
    font-size: 10px;
    padding-left: 3em;
    position: relative;
}
.client-menu-link-container .client-menu-link button:before {
    content:"";
    position: absolute;
    background: var(--primary-color);
    mask-repeat: no-repeat;
    mask-position: center;
    width: 1.3em;
    height: 1.3em;
    left: 11px;
    top: 16px;


}
.client-menu-link-container .dashboard-link button:before {
    mask-image: url('../img/icon-dashboard.svg');
}
.client-menu-link-container .logout-link button:before {
    mask-image: url('../img/icon-logout.svg');
}
.sub-client-menu
{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sub-client-menu-icon-container {
    border: 4px solid #FFFFFF;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.21);
    background-color: var(--primary-color);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-client-menu-icon {
    background-color: white;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 4em;
    height: 4em;
    mask-image: url('../img/icon-client-login.svg');
}


.client-online {
    display: inline-block;
    text-align: center;
    border: 1px solid var(--primary-border-color);
    border-radius: 20px;
    padding: 5px 10px;
    margin-top: 10px;
}
.client-online-indicator {
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background: #2EC522;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 7px #2EC522;
}

.client-menu-role{ font-size: 0.7rem; text-align: center}
.client-menu-name{ font-size: 0.9rem; font-weight: bold; text-align: center}

.client-menu .close-button {
    background: #F4F4F4;
    right: 0;
    top: 0;
}

.client-menu .close-button > div {
    width: 41%;
    left: 8px;
    top: 13px;
    height: 0.11em;
}