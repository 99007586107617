.search-container {
  display: flex;

  padding: 1em;
}

.search-input {
  display: block;
  width: 100%;
  padding: 1em;
  border: 1px solid var(--primary-border-color);
}


.search-list {
  overflow-y: scroll;
  height: calc(100vh - 130px);
  margin-right: 5px;
}

/* width */
.search-list::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.search-list::-webkit-scrollbar-track {
  background: #f7f7f7;
  margin-right: 10px;

}

/* Handle */
.search-list::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

