.favorites-menu-wrap {
  position: absolute;
  top: 0;
  right: 67px;
  width: 270px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin-left: 5em;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.25);
  z-index: 1;
  background: white;
}


.favorites-menu-wrap .title {
  padding: 2em;
  border-bottom: 1px solid var(--primary-border-color);
}
.favorites-menu-wrap h2 {
  margin-bottom: 0;
  padding-left: 2.4em;
  padding-top: 3px;
  position: relative;
  font-size: 14px;
  font-weight: normal;
}
.favorites-menu-wrap h2:before {
  content:"";
  position: absolute;
  width:100%;
  height: 1.5rem;
  top: 0;
  left: 0;
  -webkit-mask-image: url('../img/icon-location.svg');
  mask-image: url('../img/icon-location.svg');
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-size: 1.5rem;

}