.main-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 105px;
    height: 100vh;
    height: -webkit-fill-available;
    background: #fff;
    z-index: 100;
    transition: right 0.2s;
}

body.main-menu-is-open .main-menu {
    right: 0;
}

.main-menu-icon {
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    background: #fff;
    border: 0;
    left: 1.25em;
    top: 50%;
    transform: translateY(-50%);
}

.main-menu-icon > div {
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--primary-color);
    border-radius: 2em;
    left: 0;
    top: 0;
}


.menu-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: 5;
    background: white;
}

.menu-wrap .item {
    display: block;
    border-bottom: 1px solid var(--primary-border-color);
    border-collapse: collapse;
    cursor: pointer;
    color: #333;
}

.menu-wrap .item:hover,
.menu-wrap .item.active {
    background: var(--primary-color);
    color: white
}


.menu-wrap .item:hover:not(.home) .inner:before,
.menu-wrap .item.active:not(.home) .inner:before {
    background: white;
}

.menu-wrap .item .inner {
    position: relative;
    text-align: center;
    height: 100px;
    padding-top: 59px;
}

.menu-wrap .item .inner:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2em;
    top: 30px;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 2.4em;
}

.menu-wrap .item.home {
    background: #F5F5F5;

}


.menu-wrap .item.home .inner {
    top: 0
}

.menu-wrap .item.home .inner:before {
    background-image: url('../img/icon-logo.svg');
    background-size: 65%;
    height: 5em;
    top: 15px
}

.menu-wrap .item:not(.home) .inner:before {
    background-color: var(--primary-color);
    mask-repeat: no-repeat;
    mask-position: center;
}


.menu-wrap .item.first {
    border-top: 1px solid var(--primary-border-color);
}

.menu-wrap .item.search .inner:before {
    mask-image: url('../img/icon-search.svg');
}

.menu-wrap .item.location .inner:before {
    mask-image: url('../img/icon-location.svg');
}

.menu-wrap .item.gallery .inner:before {
    mask-image: url('../img/icon-gallery.svg');
}

.menu-wrap .item.brochure .inner:before {
    mask-image: url('../img/icon-brochure.svg');
}

.menu-wrap .item.filter .inner:before {
    mask-image: url('../img/icon-filter.svg');
}


.menu-wrap .menu-footer .login-btn {
    display: block;
    border-radius: 0;
    position: relative;
    padding: 20px 10px;
    line-height: 12px;
    font-size: 10px;
    width: 100%;
    font-weight: normal;
    text-align: left;
    padding-left: 36px;
}

.menu-wrap .menu-footer .login-btn:before {
    position: absolute;
    content: "";
    background-color: white;
    mask-repeat: no-repeat;
    mask-position: center;

    width: 2em;
    height: 2em;
    top: 22px;
    left: 14px;
}

.menu-wrap .menu-footer .client-login-container .login-btn {
    background: #F8F8F8;
    color: #333;
    border-top: 1px solid var(--primary-border-color);
}

.menu-wrap .menu-footer .client-login-container .login-btn:before {
    background-color: var(--primary-color);
    mask-image: url('../img/icon-client-login.svg');
}

.menu-wrap .menu-footer .user-login-container .login-btn:before {
    mask-image: url('../img/icon-user-login.svg');
}

.menu-wrap .menu-footer .login-btn .online-indicator {
    position: absolute;
    left: 39px;
    top: 36px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: #2EC522;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 7px #2ec522;
}
.main-menu.narrow .menu-wrap .menu-footer .login-btn .online-indicator {
    left: 21px;
}
.main-menu.narrow {
    width: 67px;
}

.main-menu.narrow .menu-wrap {
    box-shadow: 0px 4px 25px 3px rgba(0, 0, 0, 0.15);
}

.main-menu.narrow .menu-wrap .item span,
.main-menu .menu-wrap .login-btn span {
    display: none;
}


.main-menu.narrow .menu-wrap .item:not(.home) .inner:before {
    top: 40px;
}

.main-menu .menu-wrap .login-btn {
    padding: 30px 10px;
}

.main-menu .menu-wrap .login-btn:before {
    height: 2.5em;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
}

.main-menu.narrow .menu-wrap .item.home .inner:before {
    background-image: url('../img/icon-logo-narrow.svg');

}

.side-menu {
    position: absolute;
    top: 0;
    right: 67px;
    width: 270px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin-left: 5em;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.25);
    z-index: 1;
    background: white;
}

.side-menu .title {
    padding: 2em;
    border-bottom: 1px solid var(--primary-border-color);
}

.side-menu h2 {
    margin-bottom: 0;
    padding-left: 2.4em;
    padding-top: 3px;
    position: relative;
    font-size: 14px;
    font-weight: normal;
}

.side-menu h2:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5rem;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    mask-repeat: no-repeat;
    mask-size: 1.5rem;

}

.side-menu.location-menu {
}

.side-menu.location-menu h2:before {
    -webkit-mask-image: url('../img/icon-location.svg');
    mask-image: url('../img/icon-location.svg');
}

.side-menu.gallery-menu {
  z-index: 3;
}

.side-menu.gallery-menu h2:before {
    -webkit-mask-image: url('../img/icon-gallery.svg');
    mask-image: url('../img/icon-gallery.svg');
}

.side-menu.filter-menu {
    z-index: 4
}

.side-menu.filter-menu h2:before {
    -webkit-mask-image: url('../img/icon-filter.svg');
    mask-image: url('../img/icon-filter.svg');
}

.side-menu.favorites-menu {
    width: 317px;
}
.side-menu.favorites-menu h2:before {
    -webkit-mask-image: url('../img/icon-like.svg');
    mask-image: url('../img/icon-like.svg');
}

.side-menu.search-menu h2:before {
    -webkit-mask-image: url('../img/icon-search.svg');
    mask-image: url('../img/icon-search.svg');
}



